import logo from './logo.svg';
import './App.css';

const LoadingWidget = () => <div className="loader loader--style5" title="4">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    width="24px" height="30px" viewBox="0 0 24 30" style={{enableBackground:"new 0 0 50 50"}}>
    <rect x="0" y="0" width="4" height="10" fill="#333">
      <animateTransform attributeType="xml"
        attributeName="transform" type="translate"
        values="0 0; 0 20; 0 0"
        begin="0" dur="0.6s" repeatCount="indefinite" />
    </rect>
    <rect x="10" y="0" width="4" height="10" fill="#333">
      <animateTransform attributeType="xml"
        attributeName="transform" type="translate"
        values="0 0; 0 20; 0 0"
        begin="0.2s" dur="0.6s" repeatCount="indefinite" />
    </rect>
    <rect x="20" y="0" width="4" height="10" fill="#333">
      <animateTransform attributeType="xml"
        attributeName="transform" type="translate"
        values="0 0; 0 20; 0 0"
        begin="0.4s" dur="0.6s" repeatCount="indefinite" />
    </rect>
  </svg>
</div>

function App() {
  return (
    <div className="App">
      <div className="first">Anzan</div>
      <div className="second">Ventures</div>
      <div className="third">
        <LoadingWidget />
        Coming Soon
        <LoadingWidget />
      </div>
      

    </div>
  );
}

export default App;
